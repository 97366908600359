/*=======================
    Header Area 
========================*/

.color-picker {
    right: -190px;
}

.app-header {
    &.header--transparent {
        left: 0;
        position: absolute;
        right: 0;
        top: 38px;
        z-index: 2;
        @media #{$lg-layout} {
            top: 38px;
        }
        @media #{$md-layout} {
            top: 25px;
        }
        @media #{$sm-layout} {
            top: 20px;
        }
    }
    .button-default {
        font-size: 11px;
        line-height: 32px;
        padding: 0 16px;
        background-color: #ffffff;
        color: #2d3e50;
        height: 32px;
        &:hover {
            background-color: $theme-color;
            color: #ffffff;
        }
    }
}

.mainmenu-wrapper {
    display: flex;
    justify-content: flex-end;
}

.main-menu {
    display: flex;
    margin: 0;
    li {
        a {
            color: #ffffff;
            display: block;
            font-family: "Raleway", sans-serif;
            font-size: 12px;
            font-weight: 600;
            line-height: 26px;
            margin-right: 20px;
            padding: 0 1px;
            position: relative;
            transition: all 0.3s ease 0s;
            text-decoration: none;
            text-transform: uppercase;
            &::before {
                background: $theme-color none repeat scroll 0 0;
                bottom: 0;
                content: "";
                height: 0;
                left: 50%;
                position: absolute;
                right: 50%;
                -webkit-transition-duration: 0.3s;
                transition-duration: 0.3s;
                -webkit-transition-property: left, right;
                transition-property: left, right;
                -webkit-transition-timing-function: ease-out;
                transition-timing-function: ease-out;
                z-index: -1;
            }
            &:hover {
                &::before {
                    left: 0;
                    right: 0;
                    height: 2px;
                }
            }
        }
        &.active {
            a {
                &::before {
                    left: 0;
                    right: 0;
                    height: 2px;
                }
            }
        }
    }
}

.app-header.stick {
    background: #2d3e50 none repeat scroll 0 0;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.15);
    padding: 25px 0;
    position: fixed;
    top: 0;
    z-index: 99;
}

.tap-top {
    width: 50px;
    height: 50px;
    position: fixed;
    bottom: 150px;
    right: 30px;
    z-index: 99;
    color: white;
    text-align: center;
    background: $theme-color;
    border-radius: 100%;
    font-size: 22px;
    cursor: pointer;
    line-height: 2.2;
    display: none;
    border: 1px solid white;
}