                                                                /*====================
Feature Area 
======================*/
                                                                
                                                                .feature-bg-image {
                                                                    background-position: -8.5% 63%;
                                                                    background-size: 62% auto;
                                                                    background-image: url(/assets/images/app/preview.png);
                                                                    margin-top: -112px;
                                                                    padding-top: 35px;
                                                                    background-repeat: no-repeat;
                                                                    background-size: 63% auto;
                                                                    background-position: -11% 67%;
                                                                    @media #{$md-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                    @media #{$sm-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                }
                                                                
                                                                .feature-bg-image-en-es {
                                                                    background-position: -8.5% 63%;
                                                                    background-size: 62% auto;
                                                                    background-image: url(/assets/images/app/english-to-spanish/english-to-spanish-preview-01.png);
                                                                    margin-top: -112px;
                                                                    padding-top: 35px;
                                                                    background-repeat: no-repeat;
                                                                    background-size: 63% auto;
                                                                    background-position: -11% 67%;
                                                                    @media #{$md-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                    @media #{$sm-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                }
                                                                
                                                                .feature-bg-image-en-ar {
                                                                    background-position: -8.5% 63%;
                                                                    background-size: 62% auto;
                                                                    background-image: url(/assets/images/app/english-to-arabic/english-to-arabic-preview-01.png);
                                                                    margin-top: -112px;
                                                                    padding-top: 35px;
                                                                    background-repeat: no-repeat;
                                                                    background-size: 63% auto;
                                                                    background-position: -11% 67%;
                                                                    @media #{$md-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                    @media #{$sm-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                }
                                                                
                                                                .feature-bg-image-en-cmn {
                                                                    background-position: -8.5% 63%;
                                                                    background-size: 62% auto;
                                                                    background-image: url(/assets/images/app/english-to-chinese/english-to-chinese-preview-01.png);
                                                                    margin-top: -112px;
                                                                    padding-top: 35px;
                                                                    background-repeat: no-repeat;
                                                                    background-size: 63% auto;
                                                                    background-position: -11% 67%;
                                                                    @media #{$md-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                    @media #{$sm-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                }
                                                                
                                                                .feature-bg-image-en-de {
                                                                    background-position: -8.5% 63%;
                                                                    background-size: 62% auto;
                                                                    background-image: url(/assets/images/app/english-to-german/english-to-german-preview-01.png);
                                                                    margin-top: -112px;
                                                                    padding-top: 35px;
                                                                    background-repeat: no-repeat;
                                                                    background-size: 63% auto;
                                                                    background-position: -11% 67%;
                                                                    @media #{$md-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                    @media #{$sm-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                }
                                                                
                                                                .feature-bg-image-en-ko {
                                                                    background-position: -8.5% 63%;
                                                                    background-size: 62% auto;
                                                                    background-image: url(/assets/images/app/english-to-korean/english-to-korean-preview-01.png);
                                                                    margin-top: -112px;
                                                                    padding-top: 35px;
                                                                    background-repeat: no-repeat;
                                                                    background-size: 63% auto;
                                                                    background-position: -11% 67%;
                                                                    @media #{$md-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                    @media #{$sm-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                }
                                                                
                                                                .feature-bg-image-en-ja {
                                                                    background-position: -8.5% 63%;
                                                                    background-size: 62% auto;
                                                                    background-image: url(/assets/images/app/english-to-japanese/english-to-japanese-preview-01.png);
                                                                    margin-top: -112px;
                                                                    padding-top: 35px;
                                                                    background-repeat: no-repeat;
                                                                    background-size: 63% auto;
                                                                    background-position: -11% 67%;
                                                                    @media #{$md-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                    @media #{$sm-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                }
                                                                
                                                                .feature-bg-image-en-ta {
                                                                    background-position: -8.5% 63%;
                                                                    background-size: 62% auto;
                                                                    background-image: url(/assets/images/app/english-to-tagalog/english-to-tagalog-preview-01.png);
                                                                    margin-top: -112px;
                                                                    padding-top: 35px;
                                                                    background-repeat: no-repeat;
                                                                    background-size: 63% auto;
                                                                    background-position: -11% 67%;
                                                                    @media #{$md-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                    @media #{$sm-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                }
                                                                
                                                                .feature-bg-image-en-pt {
                                                                    background-position: -8.5% 63%;
                                                                    background-size: 62% auto;
                                                                    background-image: url(/assets/images/app/english-to-portuguese/english-to-portuguese-preview-01.png);
                                                                    margin-top: -112px;
                                                                    padding-top: 35px;
                                                                    background-repeat: no-repeat;
                                                                    background-size: 63% auto;
                                                                    background-position: -11% 67%;
                                                                    @media #{$md-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                    @media #{$sm-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                }
                                                                
                                                                .feature-bg-image-en-ru {
                                                                    background-position: -8.5% 63%;
                                                                    background-size: 62% auto;
                                                                    background-image: url(/assets/images/app/english-to-russian/english-to-russian-preview-01.png);
                                                                    margin-top: -112px;
                                                                    padding-top: 35px;
                                                                    background-repeat: no-repeat;
                                                                    background-size: 63% auto;
                                                                    background-position: -11% 67%;
                                                                    @media #{$md-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                    @media #{$sm-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                }
                                                                
                                                                .feature-bg-image-en-it {
                                                                    background-position: -8.5% 63%;
                                                                    background-size: 62% auto;
                                                                    background-image: url(/assets/images/app/english-to-italian/english-to-italian-preview-01.png);
                                                                    margin-top: -112px;
                                                                    padding-top: 35px;
                                                                    background-repeat: no-repeat;
                                                                    background-size: 63% auto;
                                                                    background-position: -11% 67%;
                                                                    @media #{$md-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                    @media #{$sm-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                }
                                                                
                                                                .feature-bg-image-en-fr {
                                                                    background-position: -8.5% 63%;
                                                                    background-size: 62% auto;
                                                                    background-image: url(/assets/images/app/english-to-french/english-to-french-preview-01.png);
                                                                    margin-top: -112px;
                                                                    padding-top: 35px;
                                                                    background-repeat: no-repeat;
                                                                    background-size: 63% auto;
                                                                    background-position: -11% 67%;
                                                                    @media #{$md-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                    @media #{$sm-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                }
                                                                
                                                                .feature-bg-image-en-hi {
                                                                    background-position: -8.5% 63%;
                                                                    background-size: 62% auto;
                                                                    background-image: url(/assets/images/app/english-to-hindi/english-to-hindi-preview-01.png);
                                                                    margin-top: -112px;
                                                                    padding-top: 35px;
                                                                    background-repeat: no-repeat;
                                                                    background-size: 63% auto;
                                                                    background-position: -11% 67%;
                                                                    @media #{$md-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                    @media #{$sm-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                }
                                                                
                                                                .feature-bg-image-en-vi {
                                                                    background-position: -8.5% 63%;
                                                                    background-size: 62% auto;
                                                                    background-image: url(/assets/images/app/english-to-vietnamese/english-to-vietnamese-preview-01.png);
                                                                    margin-top: -112px;
                                                                    padding-top: 35px;
                                                                    background-repeat: no-repeat;
                                                                    background-size: 63% auto;
                                                                    background-position: -11% 67%;
                                                                    @media #{$md-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                    @media #{$sm-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                }
                                                                
                                                                .feature-bg-image-en-pl {
                                                                    background-position: -8.5% 63%;
                                                                    background-size: 62% auto;
                                                                    background-image: url(/assets/images/app/english-to-polish/english-to-polish-preview-01.png);
                                                                    margin-top: -112px;
                                                                    padding-top: 35px;
                                                                    background-repeat: no-repeat;
                                                                    background-size: 63% auto;
                                                                    background-position: -11% 67%;
                                                                    @media #{$md-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                    @media #{$sm-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                }
                                                                
                                                                .feature-bg-image-en-el {
                                                                    background-position: -8.5% 63%;
                                                                    background-size: 62% auto;
                                                                    background-image: url(/assets/images/app/english-to-greek/english-to-greek-preview-01.png);
                                                                    margin-top: -112px;
                                                                    padding-top: 35px;
                                                                    background-repeat: no-repeat;
                                                                    background-size: 63% auto;
                                                                    background-position: -11% 67%;
                                                                    @media #{$md-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                    @media #{$sm-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                }
                                                                
                                                                .feature-bg-image-en-tr {
                                                                    background-position: -8.5% 63%;
                                                                    background-size: 62% auto;
                                                                    background-image: url(/assets/images/app/english-to-turkish/english-to-turkish-preview-01.png);
                                                                    margin-top: -112px;
                                                                    padding-top: 35px;
                                                                    background-repeat: no-repeat;
                                                                    background-size: 63% auto;
                                                                    background-position: -11% 67%;
                                                                    @media #{$md-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                    @media #{$sm-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                }
                                                                
                                                                .feature-list {
                                                                    @media #{$md-layout} {
                                                                        padding: 0 50px;
                                                                    }
                                                                }
                                                                
                                                                .feature {
                                                                    margin-bottom: 96px;
                                                                    position: relative;
                                                                    z-index: 2;
                                                                    display: flex;
                                                                    @media #{$sm-layout} {
                                                                        display: block;
                                                                        margin-bottom: 50px;
                                                                    }
                                                                    .feature-icon {
                                                                        margin-right: 38px;
                                                                        background-color: #7a9757;
                                                                        border-radius: 36px 36px 0;
                                                                        display: inline-table;
                                                                        height: 100px;
                                                                        margin-bottom: 24px;
                                                                        text-align: center;
                                                                        width: 100px;
                                                                        -webkit-transition: all 0.3s ease 0s;
                                                                        transition: all 0.3s ease 0s;
                                                                        i {
                                                                            color: #ffffff;
                                                                            display: table-cell;
                                                                            font-size: 50px;
                                                                            vertical-align: middle;
                                                                        }
                                                                    }
                                                                    .content {
                                                                        width: 408px;
                                                                        @media #{$sm-layout} {
                                                                            width: auto;
                                                                        }
                                                                        .title {
                                                                            line-height: 21px;
                                                                            margin-bottom: 11px;
                                                                            font-size: 28px;
                                                                            font-weight: 600;
                                                                            @media #{$sm-layout} {
                                                                                margin-bottom: 15px;
                                                                                font-size: 22px;
                                                                            }
                                                                        }
                                                                        .desc {
                                                                            margin-bottom: 0;
                                                                        }
                                                                    }
                                                                }
                                                                
                                                                .feature-list .feature:first-child,
                                                                .feature-list .feature:last-child {
                                                                    margin-left: -50px;
                                                                }
                                                                
                                                                .feature-list .feature:nth-child(2),
                                                                .feature-list .feature:nth-child(4) {
                                                                    margin-left: 56px;
                                                                }
                                                                
                                                                .feature-list .feature:nth-child(3) {
                                                                    margin-left: 138px;
                                                                }
                                                                
                                                                @media #{$md-layout} {
                                                                    .feature-list .feature:first-child,
                                                                    .feature-list .feature:last-child,
                                                                    .feature-list .feature:nth-child(2),
                                                                    .feature-list .feature:nth-child(4),
                                                                    .feature-list .feature:nth-child(3) {
                                                                        margin-left: 0;
                                                                    }
                                                                }
                                                                
                                                                @media #{$sm-layout} {
                                                                    .feature-list .feature:first-child,
                                                                    .feature-list .feature:last-child,
                                                                    .feature-list .feature:nth-child(2),
                                                                    .feature-list .feature:nth-child(4),
                                                                    .feature-list .feature:nth-child(3) {
                                                                        margin-left: 0;
                                                                    }
                                                                }
                                                                
                                                                .feature-area {
                                                                    &.vertical-feature {
                                                                        @media #{$lg-layout} {
                                                                            position: relative;
                                                                            margin-bottom: -200px;
                                                                            z-index: 5;
                                                                        }
                                                                        @media #{$md-layout} {
                                                                            padding-bottom: 0;
                                                                        }
                                                                    }
                                                                    &.horizontal-feature {
                                                                        margin-top: 0;
                                                                        padding-top: 120px;
                                                                        @media #{$lg-layout} {
                                                                            margin-top: 0;
                                                                            padding-top: 120px;
                                                                            padding-bottom: 0;
                                                                        }
                                                                        @media #{$md-layout} {
                                                                            margin-top: 0;
                                                                            padding-top: 80px;
                                                                            padding-bottom: 0;
                                                                        }
                                                                        @media #{$sm-layout} {
                                                                            margin-top: 0;
                                                                            padding-top: 80px;
                                                                            padding-bottom: 40px;
                                                                        }
                                                                    }
                                                                }
                                                                
                                                                .feature-bg-image-en-nl {
                                                                    background-position: -8.5% 63%;
                                                                    background-size: 62% auto;
                                                                    background-image: url(/assets/images/app/english-to-dutch/english-to-dutch-preview-01.png);
                                                                    margin-top: -112px;
                                                                    padding-top: 35px;
                                                                    background-repeat: no-repeat;
                                                                    background-size: 63% auto;
                                                                    background-position: -11% 67%;
                                                                    @media #{$md-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                    @media #{$sm-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                }
                                                                
                                                                .feature-bg-image-en-sv {
                                                                    background-position: -8.5% 63%;
                                                                    background-size: 62% auto;
                                                                    background-image: url(/assets/images/app/english-to-swedish/english-to-swedish-preview-01.png);
                                                                    margin-top: -112px;
                                                                    padding-top: 35px;
                                                                    background-repeat: no-repeat;
                                                                    background-size: 63% auto;
                                                                    background-position: -11% 67%;
                                                                    @media #{$md-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                    @media #{$sm-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                }
                                                                
                                                                .feature-bg-image-en-da {
                                                                    background-position: -8.5% 63%;
                                                                    background-size: 62% auto;
                                                                    background-image: url(/assets/images/app/english-to-danish/english-to-danish-preview-01.png);
                                                                    margin-top: -112px;
                                                                    padding-top: 35px;
                                                                    background-repeat: no-repeat;
                                                                    background-size: 63% auto;
                                                                    background-position: -11% 67%;
                                                                    @media #{$md-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                    @media #{$sm-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                }
                                                                
                                                                .feature-bg-image-en-th {
                                                                    background-position: -8.5% 63%;
                                                                    background-size: 62% auto;
                                                                    background-image: url(/assets/images/app/english-to-thai/english-to-thai-preview-01.png);
                                                                    margin-top: -112px;
                                                                    padding-top: 35px;
                                                                    background-repeat: no-repeat;
                                                                    background-size: 63% auto;
                                                                    background-position: -11% 67%;
                                                                    @media #{$md-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                    @media #{$sm-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                }
                                                                
                                                                .feature-bg-image-en-fi {
                                                                    background-position: -8.5% 63%;
                                                                    background-size: 62% auto;
                                                                    background-image: url(/assets/images/app/english-to-finnish/english-to-finnish-preview-01.png);
                                                                    margin-top: -112px;
                                                                    padding-top: 35px;
                                                                    background-repeat: no-repeat;
                                                                    background-size: 63% auto;
                                                                    background-position: -11% 67%;
                                                                    @media #{$md-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                    @media #{$sm-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                }

                                                                
                                                                .feature-bg-image-en-ro {
                                                                    background-position: -8.5% 63%;
                                                                    background-size: 62% auto;
                                                                    background-image: url(/assets/images/app/english-to-romanian/english-to-romanian-preview-01.png);
                                                                    margin-top: -112px;
                                                                    padding-top: 35px;
                                                                    background-repeat: no-repeat;
                                                                    background-size: 63% auto;
                                                                    background-position: -11% 67%;
                                                                    @media #{$md-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                    @media #{$sm-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                }

                                                                

                                                                .feature-bg-image-en-note {
                                                                    background-position: -8.5% 63%;
                                                                    background-size: 62% auto;
                                                                    background-image: url(/assets/images/app/note/note-preview-01.png);
                                                                    margin-top: -112px;
                                                                    padding-top: 35px;
                                                                    background-repeat: no-repeat;
                                                                    background-size: 63% auto;
                                                                    background-position: -11% 67%;
                                                                    @media #{$md-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                    @media #{$sm-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                }

                                                                .feature-bg-image-en-aimate {
                                                                    background-position: -8.5% 63%;
                                                                    background-size: 62% auto;
                                                                    background-image: url(/assets/images/app/aimate/aimate-preview-01.png);
                                                                    margin-top: -112px;
                                                                    padding-top: 35px;
                                                                    background-repeat: no-repeat;
                                                                    background-size: 63% auto;
                                                                    background-position: -11% 67%;
                                                                    @media #{$md-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                    @media #{$sm-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                }

                                                               
                                                                .feature-bg-image-en-todo {
                                                                    background-position: -8.5% 63%;
                                                                    background-size: 62% auto;
                                                                    background-image: url(/assets/images/app/todo/todo-preview-01.png);
                                                                    margin-top: -112px;
                                                                    padding-top: 35px;
                                                                    background-repeat: no-repeat;
                                                                    background-size: 63% auto;
                                                                    background-position: -11% 67%;
                                                                    @media #{$md-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                    @media #{$sm-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                }
                                                                


                                                                .feature-bg-image-en-nb {
                                                                    background-position: -8.5% 63%;
                                                                    background-size: 62% auto;
                                                                    background-image: url(/assets/images/app/english-to-norwegian/english-to-norwegian-preview-01.png);
                                                                    margin-top: -112px;
                                                                    padding-top: 35px;
                                                                    background-repeat: no-repeat;
                                                                    background-size: 63% auto;
                                                                    background-position: -11% 67%;
                                                                    @media #{$md-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                    @media #{$sm-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                }
                                                                
                                                                .feature-bg-image-en-hu {
                                                                    background-position: -8.5% 63%;
                                                                    background-size: 62% auto;
                                                                    background-image: url(/assets/images/app/english-to-hungarian/english-to-hungarian-preview-01.png);
                                                                    margin-top: -112px;
                                                                    padding-top: 35px;
                                                                    background-repeat: no-repeat;
                                                                    background-size: 63% auto;
                                                                    background-position: -11% 67%;
                                                                    @media #{$md-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                    @media #{$sm-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                }
                                                                
                                                                .feature-bg-image-en-lv {
                                                                    background-position: -8.5% 63%;
                                                                    background-size: 62% auto;
                                                                    background-image: url(/assets/images/app/english-to-latvian/english-to-latvian-preview-01.png);
                                                                    margin-top: -112px;
                                                                    padding-top: 35px;
                                                                    background-repeat: no-repeat;
                                                                    background-size: 63% auto;
                                                                    background-position: -11% 67%;
                                                                    @media #{$md-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                    @media #{$sm-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                }
                                                                
                                                                .feature-bg-image-en-etran {
                                                                    background-position: -8.5% 63%;
                                                                    background-size: 62% auto;
                                                                    background-image: url(/assets/images/app/etranslator/etranslator-preview-01.png);
                                                                    margin-top: -112px;
                                                                    padding-top: 35px;
                                                                    background-repeat: no-repeat;
                                                                    background-size: 63% auto;
                                                                    background-position: -11% 67%;
                                                                    @media #{$md-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                    @media #{$sm-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                }
                                                                .feature-bg-image-en-gtranslate {
                                                                    background-position: -8.5% 63%;
                                                                    background-size: 62% auto;
                                                                    background-image: url(/assets/images/app/gtranslate/gtranslate-preview-01.png);
                                                                    margin-top: -112px;
                                                                    padding-top: 35px;
                                                                    background-repeat: no-repeat;
                                                                    background-size: 63% auto;
                                                                    background-position: -11% 67%;
                                                                    @media #{$md-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                    @media #{$sm-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                }
                                                                
                                                                .feature-bg-image-en-tago {
                                                                    background-position: -8.5% 63%;
                                                                    background-size: 62% auto;
                                                                    background-image: url(/assets/images/app/tago/tago-preview-01.png);
                                                                    margin-top: -112px;
                                                                    padding-top: 35px;
                                                                    background-repeat: no-repeat;
                                                                    background-size: 63% auto;
                                                                    background-position: -11% 67%;
                                                                    @media #{$md-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                    @media #{$sm-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                }
                                                                
                                                                .feature-bg-image-en-bulgo {
                                                                    background-position: -8.5% 63%;
                                                                    background-size: 62% auto;
                                                                    background-image: url(/assets/images/app/bulgo/bulgo-preview-01.png);
                                                                    margin-top: -112px;
                                                                    padding-top: 35px;
                                                                    background-repeat: no-repeat;
                                                                    background-size: 63% auto;
                                                                    background-position: -11% 67%;
                                                                    @media #{$md-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                    @media #{$sm-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                }

																.feature-bg-image-en-catago {
                                                                    background-position: -8.5% 63%;
                                                                    background-size: 62% auto;
                                                                    background-image: url(/assets/images/app/catago/catago-preview-01.png);
                                                                    margin-top: -112px;
                                                                    padding-top: 35px;
                                                                    background-repeat: no-repeat;
                                                                    background-size: 63% auto;
                                                                    background-position: -11% 67%;
                                                                    @media #{$md-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                    @media #{$sm-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                }

																.feature-bg-image-en-esgot {
                                                                    background-position: -8.5% 63%;
                                                                    background-size: 62% auto;
                                                                    background-image: url(/assets/images/app/esgot/esgot-preview-01.png);
                                                                    margin-top: -112px;
                                                                    padding-top: 35px;
                                                                    background-repeat: no-repeat;
                                                                    background-size: 63% auto;
                                                                    background-position: -11% 67%;
                                                                    @media #{$md-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                    @media #{$sm-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                }

																.feature-bg-image-en-translator {
                                                                    background-position: -8.5% 63%;
                                                                    background-size: 62% auto;
                                                                    background-image: url(/assets/images/app/translator/translator-preview-01.png);
                                                                    margin-top: -112px;
                                                                    padding-top: 35px;
                                                                    background-repeat: no-repeat;
                                                                    background-size: 63% auto;
                                                                    background-position: -11% 67%;
                                                                    @media #{$md-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                    @media #{$sm-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                }
                                                                .feature-bg-image-en-dego {
                                                                    background-position: -8.5% 63%;
                                                                    background-size: 62% auto;
                                                                    background-image: url(/assets/images/app/dego/dego-preview-01.png);
                                                                    margin-top: -112px;
                                                                    padding-top: 35px;
                                                                    background-repeat: no-repeat;
                                                                    background-size: 63% auto;
                                                                    background-position: -11% 67%;
                                                                    @media #{$md-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                    @media #{$sm-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                }

                                                                .feature-bg-image-en-frangot {
                                                                    background-position: -8.5% 63%;
                                                                    background-size: 62% auto;
                                                                    background-image: url(/assets/images/app/frangot/frangot-preview-01.png);
                                                                    margin-top: -112px;
                                                                    padding-top: 35px;
                                                                    background-repeat: no-repeat;
                                                                    background-size: 63% auto;
                                                                    background-position: -11% 67%;
                                                                    @media #{$md-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                    @media #{$sm-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                }
                                                                .feature-bg-image-en-vigot {
                                                                    background-position: -8.5% 63%;
                                                                    background-size: 62% auto;
                                                                    background-image: url(/assets/images/app/vigot/vigot-preview-01.png);
                                                                    margin-top: -112px;
                                                                    padding-top: 35px;
                                                                    background-repeat: no-repeat;
                                                                    background-size: 63% auto;
                                                                    background-position: -11% 67%;
                                                                    @media #{$md-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                    @media #{$sm-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                }
                                                                .feature-bg-image-en-hangot {
                                                                    background-position: -8.5% 63%;
                                                                    background-size: 62% auto;
                                                                    background-image: url(/assets/images/app/hangot/hangot-preview-01.png);
                                                                    margin-top: -112px;
                                                                    padding-top: 35px;
                                                                    background-repeat: no-repeat;
                                                                    background-size: 63% auto;
                                                                    background-position: -11% 67%;
                                                                    @media #{$md-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                    @media #{$sm-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                }
    
                                                                .feature-bg-image-en-japangot {
                                                                    background-position: -8.5% 63%;
                                                                    background-size: 62% auto;
                                                                    background-image: url(/assets/images/app/japangot/japangot-preview-01.png);
                                                                    margin-top: -112px;
                                                                    padding-top: 35px;
                                                                    background-repeat: no-repeat;
                                                                    background-size: 63% auto;
                                                                    background-position: -11% 67%;
                                                                    @media #{$md-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                    @media #{$sm-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                }
                                                                .feature-bg-image-en-pogot {
                                                                    background-position: -8.5% 63%;
                                                                    background-size: 62% auto;
                                                                    background-image: url(/assets/images/app/pogot/pogot-preview-01.png);
                                                                    margin-top: -112px;
                                                                    padding-top: 35px;
                                                                    background-repeat: no-repeat;
                                                                    background-size: 63% auto;
                                                                    background-position: -11% 67%;
                                                                    @media #{$md-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                    @media #{$sm-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                }
                                                                .feature-bg-image-en-degot {
                                                                    background-position: -8.5% 63%;
                                                                    background-size: 62% auto;
                                                                    background-image: url(/assets/images/app/degot/degot-preview-01.png);
                                                                    margin-top: -112px;
                                                                    padding-top: 35px;
                                                                    background-repeat: no-repeat;
                                                                    background-size: 63% auto;
                                                                    background-position: -11% 67%;
                                                                    @media #{$md-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                    @media #{$sm-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                }
                                                                .feature-bg-image-en-italygot {
                                                                    background-position: -8.5% 63%;
                                                                    background-size: 62% auto;
                                                                    background-image: url(/assets/images/app/italygot/italygot-preview-01.png);
                                                                    margin-top: -112px;
                                                                    padding-top: 35px;
                                                                    background-repeat: no-repeat;
                                                                    background-size: 63% auto;
                                                                    background-position: -11% 67%;
                                                                    @media #{$md-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                    @media #{$sm-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                }
                                                                .feature-bg-image-en-voicegot {
                                                                    background-position: -8.5% 63%;
                                                                    background-size: 62% auto;
                                                                    background-image: url(/assets/images/app/voicegot/voicegot-preview-01.png);
                                                                    margin-top: -112px;
                                                                    padding-top: 35px;
                                                                    background-repeat: no-repeat;
                                                                    background-size: 63% auto;
                                                                    background-position: -11% 67%;
                                                                    @media #{$md-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                    @media #{$sm-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                }

                                                                .feature-bg-image-en-aimate {
                                                                    background-position: -8.5% 63%;
                                                                    background-size: 62% auto;
                                                                    background-image: url(/assets/images/app/aimate/aimate-preview-01.png);
                                                                    margin-top: -112px;
                                                                    padding-top: 35px;
                                                                    background-repeat: no-repeat;
                                                                    background-size: 63% auto;
                                                                    background-position: -11% 67%;
                                                                    @media #{$md-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                    @media #{$sm-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                }

                                                                .feature-bg-image-en-diary {
                                                                    background-position: -8.5% 63%;
                                                                    background-size: 62% auto;
                                                                    background-image: url(/assets/images/app/diary/diary-preview-01.png);
                                                                    margin-top: -112px;
                                                                    padding-top: 35px;
                                                                    background-repeat: no-repeat;
                                                                    background-size: 63% auto;
                                                                    background-position: -11% 67%;
                                                                    @media #{$md-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                    @media #{$sm-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                }

                                                                .feature-bg-image-en-cameragot {
                                                                    background-position: -8.5% 63%;
                                                                    background-size: 62% auto;
                                                                    background-image: url(/assets/images/app/cameragot/cameragot-preview-01.png);
                                                                    margin-top: -112px;
                                                                    padding-top: 35px;
                                                                    background-repeat: no-repeat;
                                                                    background-size: 63% auto;
                                                                    background-position: -11% 67%;
                                                                    @media #{$md-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                    @media #{$sm-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                }
                                                                .feature-bg-image-en-thaigot {
                                                                    background-position: -8.5% 63%;
                                                                    background-size: 62% auto;
                                                                    background-image: url(/assets/images/app/thaigot/thaigot-preview-01.png);
                                                                    margin-top: -112px;
                                                                    padding-top: 35px;
                                                                    background-repeat: no-repeat;
                                                                    background-size: 63% auto;
                                                                    background-position: -11% 67%;
                                                                    @media #{$md-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                    @media #{$sm-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                }
                                                                .feature-bg-image-en-turkgot {
                                                                    background-position: -8.5% 63%;
                                                                    background-size: 62% auto;
                                                                    background-image: url(/assets/images/app/turkgot/turkgot-preview-01.png);
                                                                    margin-top: -112px;
                                                                    padding-top: 35px;
                                                                    background-repeat: no-repeat;
                                                                    background-size: 63% auto;
                                                                    background-position: -11% 67%;
                                                                    @media #{$md-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                    @media #{$sm-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                }
                                                                .feature-bg-image-en-tagagot {
                                                                    background-position: -8.5% 63%;
                                                                    background-size: 62% auto;
                                                                    background-image: url(/assets/images/app/tagagot/tagagot-preview-01.png);
                                                                    margin-top: -112px;
                                                                    padding-top: 35px;
                                                                    background-repeat: no-repeat;
                                                                    background-size: 63% auto;
                                                                    background-position: -11% 67%;
                                                                    @media #{$md-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                    @media #{$sm-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                }
                                                                
                                                                .feature-bg-image-en-czechgo {
                                                                    background-position: -8.5% 63%;
                                                                    background-size: 62% auto;
                                                                    background-image: url(/assets/images/app/czechgo/czechgo-preview-01.png);
                                                                    margin-top: -112px;
                                                                    padding-top: 35px;
                                                                    background-repeat: no-repeat;
                                                                    background-size: 63% auto;
                                                                    background-position: -11% 67%;
                                                                    @media #{$md-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                    @media #{$sm-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                }
                                                                .feature-bg-image-en-danskgo {
                                                                    background-position: -8.5% 63%;
                                                                    background-size: 62% auto;
                                                                    background-image: url(/assets/images/app/danskgo/danskgo-preview-01.png);
                                                                    margin-top: -112px;
                                                                    padding-top: 35px;
                                                                    background-repeat: no-repeat;
                                                                    background-size: 63% auto;
                                                                    background-position: -11% 67%;
                                                                    @media #{$md-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                    @media #{$sm-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                }                
                                                                .feature-bg-image-en-fingo {
                                                                    background-position: -8.5% 63%;
                                                                    background-size: 62% auto;
                                                                    background-image: url(/assets/images/app/fingo/fingo-preview-01.png);
                                                                    margin-top: -112px;
                                                                    padding-top: 35px;
                                                                    background-repeat: no-repeat;
                                                                    background-size: 63% auto;
                                                                    background-position: -11% 67%;
                                                                    @media #{$md-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                    @media #{$sm-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                }

                                                                .feature-bg-image-en-danishgot {
                                                                    background-position: -8.5% 63%;
                                                                    background-size: 62% auto;
                                                                    background-image: url(/assets/images/app/danishgot/danishgot-preview-01.png);
                                                                    margin-top: -112px;
                                                                    padding-top: 35px;
                                                                    background-repeat: no-repeat;
                                                                    background-size: 63% auto;
                                                                    background-position: -11% 67%;
                                                                    @media #{$md-layout} {
                                                                    margin-top: 0;
                                                                    background-image: inherit;
                                                                    }
                                                                    @media #{$sm-layout} {
                                                                    margin-top: 0;
                                                                    background-image: inherit;
                                                                    }
                                                                }
                                                                
                                                                .feature-bg-image-en-dutchgot {
                                                                    background-position: -8.5% 63%;
                                                                    background-size: 62% auto;
                                                                    background-image: url(/assets/images/app/dutchgot/dutchgot-preview-01.png);
                                                                    margin-top: -112px;
                                                                    padding-top: 35px;
                                                                    background-repeat: no-repeat;
                                                                    background-size: 63% auto;
                                                                    background-position: -11% 67%;
                                                                    @media #{$md-layout} {
                                                                    margin-top: 0;
                                                                    background-image: inherit;
                                                                    }
                                                                    @media #{$sm-layout} {
                                                                    margin-top: 0;
                                                                    background-image: inherit;
                                                                    }
                                                                }
                                                                
                                                                .feature-bg-image-en-polgot {
                                                                    background-position: -8.5% 63%;
                                                                    background-size: 62% auto;
                                                                    background-image: url(/assets/images/app/polgot/polgot-preview-01.png);
                                                                    margin-top: -112px;
                                                                    padding-top: 35px;
                                                                    background-repeat: no-repeat;
                                                                    background-size: 63% auto;
                                                                    background-position: -11% 67%;
                                                                    @media #{$md-layout} {
                                                                    margin-top: 0;
                                                                    background-image: inherit;
                                                                    }
                                                                    @media #{$sm-layout} {
                                                                    margin-top: 0;
                                                                    background-image: inherit;
                                                                    }
                                                                }
                                                                
                                                                .feature-bg-image-en-rusgot {
                                                                    background-position: -8.5% 63%;
                                                                    background-size: 62% auto;
                                                                    background-image: url(/assets/images/app/rusgot/rusgot-preview-01.png);
                                                                    margin-top: -112px;
                                                                    padding-top: 35px;
                                                                    background-repeat: no-repeat;
                                                                    background-size: 63% auto;
                                                                    background-position: -11% 67%;
                                                                    @media #{$md-layout} {
                                                                    margin-top: 0;
                                                                    background-image: inherit;
                                                                    }
                                                                    @media #{$sm-layout} {
                                                                    margin-top: 0;
                                                                    background-image: inherit;
                                                                    }
                                                                }
                                                                
                                                                .feature-bg-image-en-ukrainegot {
                                                                    background-position: -8.5% 63%;
                                                                    background-size: 62% auto;
                                                                    background-image: url(/assets/images/app/ukrainegot/ukrainegot-preview-01.png);
                                                                    margin-top: -112px;
                                                                    padding-top: 35px;
                                                                    background-repeat: no-repeat;
                                                                    background-size: 63% auto;
                                                                    background-position: -11% 67%;
                                                                    @media #{$md-layout} {
                                                                    margin-top: 0;
                                                                    background-image: inherit;
                                                                    }
                                                                    @media #{$sm-layout} {
                                                                    margin-top: 0;
                                                                    background-image: inherit;
                                                                    }
                                                                }

                                                                .feature-bg-image-en-romango {
                                                                    background-position: -8.5% 63%;
                                                                    background-size: 62% auto;
                                                                    background-image: url(/assets/images/app/romango/romango-preview-01.png);
                                                                    margin-top: -112px;
                                                                    padding-top: 35px;
                                                                    background-repeat: no-repeat;
                                                                    background-size: 63% auto;
                                                                    background-position: -11% 67%;
                                                                    @media #{$md-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                    @media #{$sm-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                }
                                                                .feature-bg-image-en-norwaygo {
                                                                    background-position: -8.5% 63%;
                                                                    background-size: 62% auto;
                                                                    background-image: url(/assets/images/app/norwaygo/norwaygo-preview-01.png);
                                                                    margin-top: -112px;
                                                                    padding-top: 35px;
                                                                    background-repeat: no-repeat;
                                                                    background-size: 63% auto;
                                                                    background-position: -11% 67%;
                                                                    @media #{$md-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                    @media #{$sm-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                }
                                                                .feature-bg-image-en-hungo {
                                                                    background-position: -8.5% 63%;
                                                                    background-size: 62% auto;
                                                                    background-image: url(/assets/images/app/hungo/hungo-preview-01.png);
                                                                    margin-top: -112px;
                                                                    padding-top: 35px;
                                                                    background-repeat: no-repeat;
                                                                    background-size: 63% auto;
                                                                    background-position: -11% 67%;
                                                                    @media #{$md-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                    @media #{$sm-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                }
                                                                .feature-bg-image-en-ukrainego {
                                                                    background-position: -8.5% 63%;
                                                                    background-size: 62% auto;
                                                                    background-image: url(/assets/images/app/ukrainego/ukrainego-preview-01.png);
                                                                    margin-top: -112px;
                                                                    padding-top: 35px;
                                                                    background-repeat: no-repeat;
                                                                    background-size: 63% auto;
                                                                    background-position: -11% 67%;
                                                                    @media #{$md-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                    @media #{$sm-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                }
                                                                .feature-bg-image-en-japango {
                                                                    background-position: -8.5% 63%;
                                                                    background-size: 62% auto;
                                                                    background-image: url(/assets/images/app/japango/japango-preview-01.png);
                                                                    margin-top: -112px;
                                                                    padding-top: 35px;
                                                                    background-repeat: no-repeat;
                                                                    background-size: 63% auto;
                                                                    background-position: -11% 67%;
                                                                    @media #{$md-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                    @media #{$sm-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                }
                                                                .feature-bg-image-en-serbgo {
                                                                    background-position: -8.5% 63%;
                                                                    background-size: 62% auto;
                                                                    background-image: url(/assets/images/app/serbgo/serbgo-preview-01.png);
                                                                    margin-top: -112px;
                                                                    padding-top: 35px;
                                                                    background-repeat: no-repeat;
                                                                    background-size: 63% auto;
                                                                    background-position: -11% 67%;
                                                                    @media #{$md-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                    @media #{$sm-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                }
                                                                .feature-bg-image-en-hingo {
                                                                    background-position: -8.5% 63%;
                                                                    background-size: 62% auto;
                                                                    background-image: url(/assets/images/app/hingo/hingo-preview-01.png);
                                                                    margin-top: -112px;
                                                                    padding-top: 35px;
                                                                    background-repeat: no-repeat;
                                                                    background-size: 63% auto;
                                                                    background-position: -11% 67%;
                                                                    @media #{$md-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                    @media #{$sm-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                }
                                                                .feature-bg-image-en-chigo {
                                                                    background-position: -8.5% 63%;
                                                                    background-size: 62% auto;
                                                                    background-image: url(/assets/images/app/chigo/chigo-preview-01.png);
                                                                    margin-top: -112px;
                                                                    padding-top: 35px;
                                                                    background-repeat: no-repeat;
                                                                    background-size: 63% auto;
                                                                    background-position: -11% 67%;
                                                                    @media #{$md-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                    @media #{$sm-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                }
                                                                .feature-bg-image-en-indogo {
                                                                    background-position: -8.5% 63%;
                                                                    background-size: 62% auto;
                                                                    background-image: url(/assets/images/app/indogo/indogo-preview-01.png);
                                                                    margin-top: -112px;
                                                                    padding-top: 35px;
                                                                    background-repeat: no-repeat;
                                                                    background-size: 63% auto;
                                                                    background-position: -11% 67%;
                                                                    @media #{$md-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                    @media #{$sm-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                }
                                                                .feature-bg-image-en-swedgo {
                                                                    background-position: -8.5% 63%;
                                                                    background-size: 62% auto;
                                                                    background-image: url(/assets/images/app/swedgo/swedgo-preview-01.png);
                                                                    margin-top: -112px;
                                                                    padding-top: 35px;
                                                                    background-repeat: no-repeat;
                                                                    background-size: 63% auto;
                                                                    background-position: -11% 67%;
                                                                    @media #{$md-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                    @media #{$sm-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                }

                                                                .feature-bg-image-en-portgo {
                                                                    background-position: -8.5% 63%;
                                                                    background-size: 62% auto;
                                                                    background-image: url(/assets/images/app/portgo/portgo-preview-01.png);
                                                                    margin-top: -112px;
                                                                    padding-top: 35px;
                                                                    background-repeat: no-repeat;
                                                                    background-size: 63% auto;
                                                                    background-position: -11% 67%;
                                                                    @media #{$md-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                    @media #{$sm-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                }
                                                                
                                                                .feature-bg-image-en-frango {
                                                                    background-position: -8.5% 63%;
                                                                    background-size: 62% auto;
                                                                    background-image: url(/assets/images/app/frango/frango-preview-01.png);
                                                                    margin-top: -112px;
                                                                    padding-top: 35px;
                                                                    background-repeat: no-repeat;
                                                                    background-size: 63% auto;
                                                                    background-position: -11% 67%;
                                                                    @media #{$md-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                    @media #{$sm-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                }
                                                                
                                                                .feature-bg-image-en-itago {
                                                                    background-position: -8.5% 63%;
                                                                    background-size: 62% auto;
                                                                    background-image: url(/assets/images/app/itago/itago-preview-01.png);
                                                                    margin-top: -112px;
                                                                    padding-top: 35px;
                                                                    background-repeat: no-repeat;
                                                                    background-size: 63% auto;
                                                                    background-position: -11% 67%;
                                                                    @media #{$md-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                    @media #{$sm-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                }
                                                                
                                                                .feature-bg-image-en-hango {
                                                                    background-position: -8.5% 63%;
                                                                    background-size: 62% auto;
                                                                    background-image: url(/assets/images/app/hango/hango-preview-01.png);
                                                                    margin-top: -112px;
                                                                    padding-top: 35px;
                                                                    background-repeat: no-repeat;
                                                                    background-size: 63% auto;
                                                                    background-position: -11% 67%;
                                                                    @media #{$md-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                    @media #{$sm-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                }
                                                                
                                                                .feature-bg-image-en-brago {
                                                                    background-position: -8.5% 63%;
                                                                    background-size: 62% auto;
                                                                    background-image: url(/assets/images/app/brago/brago-preview-01.png);
                                                                    margin-top: -112px;
                                                                    padding-top: 35px;
                                                                    background-repeat: no-repeat;
                                                                    background-size: 63% auto;
                                                                    background-position: -11% 67%;
                                                                    @media #{$md-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                    @media #{$sm-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                }
                                                                
                                                                .feature-bg-image-en-espago {
                                                                    background-position: -8.5% 63%;
                                                                    background-size: 62% auto;
                                                                    background-image: url(/assets/images/app/espago/espago-preview-01.png);
                                                                    margin-top: -112px;
                                                                    padding-top: 35px;
                                                                    background-repeat: no-repeat;
                                                                    background-size: 63% auto;
                                                                    background-position: -11% 67%;
                                                                    @media #{$md-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                    @media #{$sm-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                }

                                                                .feature-bg-image-en-ai-image-generator {
                                                                    background-position: -8.5% 63%;
                                                                    background-size: 62% auto;
                                                                    background-image: url(/assets/images/app/ai-image-generator/ai-image-generator-preview-01.png);
                                                                    margin-top: -112px;
                                                                    padding-top: 35px;
                                                                    background-repeat: no-repeat;
                                                                    background-size: 63% auto;
                                                                    background-position: -11% 67%;
                                                                    @media #{$md-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                    @media #{$sm-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                }

                                                                
                                                                .feature-bg-image-en-dutgo {
                                                                    background-position: -8.5% 63%;
                                                                    background-size: 62% auto;
                                                                    background-image: url(/assets/images/app/dutgo/dutgo-preview-01.png);
                                                                    margin-top: -112px;
                                                                    padding-top: 35px;
                                                                    background-repeat: no-repeat;
                                                                    background-size: 63% auto;
                                                                    background-position: -11% 67%;
                                                                    @media #{$md-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                    @media #{$sm-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                }
                                                                
                                                                .feature-bg-image-en-rusgo {
                                                                    background-position: -8.5% 63%;
                                                                    background-size: 62% auto;
                                                                    background-image: url(/assets/images/app/rusgo/rusgo-preview-01.png);
                                                                    margin-top: -112px;
                                                                    padding-top: 35px;
                                                                    background-repeat: no-repeat;
                                                                    background-size: 63% auto;
                                                                    background-position: -11% 67%;
                                                                    @media #{$md-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                    @media #{$sm-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                }
                                                                
                                                                .feature-bg-image-en-turkgo {
                                                                    background-position: -8.5% 63%;
                                                                    background-size: 62% auto;
                                                                    background-image: url(/assets/images/app/turkgo/turkgo-preview-01.png);
                                                                    margin-top: -112px;
                                                                    padding-top: 35px;
                                                                    background-repeat: no-repeat;
                                                                    background-size: 63% auto;
                                                                    background-position: -11% 67%;
                                                                    @media #{$md-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                    @media #{$sm-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                }
                                                                
                                                                .feature-bg-image-en-polgo {
                                                                    background-position: -8.5% 63%;
                                                                    background-size: 62% auto;
                                                                    background-image: url(/assets/images/app/polgo/polgo-preview-01.png);
                                                                    margin-top: -112px;
                                                                    padding-top: 35px;
                                                                    background-repeat: no-repeat;
                                                                    background-size: 63% auto;
                                                                    background-position: -11% 67%;
                                                                    @media #{$md-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                    @media #{$sm-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                }
                                                                
                                                                .feature-bg-image-en-vietgot {
                                                                    background-position: -8.5% 63%;
                                                                    background-size: 62% auto;
                                                                    background-image: url(/assets/images/app/vietgot/vietgot-preview-01.png);
                                                                    margin-top: -112px;
                                                                    padding-top: 35px;
                                                                    background-repeat: no-repeat;
                                                                    background-size: 63% auto;
                                                                    background-position: -11% 67%;
                                                                    @media #{$md-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                    @media #{$sm-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                }

                                                                .feature-bg-image-en-voicenote {
                                                                    background-position: -8.5% 63%;
                                                                    background-size: 62% auto;
                                                                    background-image: url(/assets/images/app/voicenote/voicenote-preview-01.png);
                                                                    margin-top: -112px;
                                                                    padding-top: 35px;
                                                                    background-repeat: no-repeat;
                                                                    background-size: 63% auto;
                                                                    background-position: -11% 67%;
                                                                    @media #{$md-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                    @media #{$sm-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                }
                                                                
                                                                .feature-bg-image-en-greekgo {
                                                                    background-position: -8.5% 63%;
                                                                    background-size: 62% auto;
                                                                    background-image: url(/assets/images/app/greekgo/greekgo-preview-01.png);
                                                                    margin-top: -112px;
                                                                    padding-top: 35px;
                                                                    background-repeat: no-repeat;
                                                                    background-size: 63% auto;
                                                                    background-position: -11% 67%;
                                                                    @media #{$md-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                    @media #{$sm-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                }
                                                                
                                                                .feature-bg-image-en-qrmaster {
                                                                    background-position: -8.5% 63%;
                                                                    background-size: 62% auto;
                                                                    background-image: url(/assets/images/app/qrmaster/qrmaster-preview-01.png);
                                                                    margin-top: -112px;
                                                                    padding-top: 35px;
                                                                    background-repeat: no-repeat;
                                                                    background-size: 63% auto;
                                                                    background-position: -11% 67%;
                                                                    @media #{$md-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                    @media #{$sm-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                }
                                                                
                                                                .feature-bg-image-en-thaigo {
                                                                    background-position: -8.5% 63%;
                                                                    background-size: 62% auto;
                                                                    background-image: url(/assets/images/app/thaigo/thaigo-preview-01.png);
                                                                    margin-top: -112px;
                                                                    padding-top: 35px;
                                                                    background-repeat: no-repeat;
                                                                    background-size: 63% auto;
                                                                    background-position: -11% 67%;
                                                                    @media #{$md-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                    @media #{$sm-layout} {
                                                                        margin-top: 0;
                                                                        background-image: inherit;
                                                                    }
                                                                }